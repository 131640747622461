<template>
  <div class="">
    <Card v-if="Show1" :cardList="cardList" :InitFlowNodeList="InitFlowNodeList" :AltUserList="AltUserList" />
  </div>
</template>

<script>
import Card from './Card.vue'
import { Dialog, Toast } from 'vant'
import { getToken } from '@/utils/auth'

import {
  getUserProfile,
  GenerateMakeCard,
  GetDraftMakeCard,
  ExistMakingApply,
  ChangeTeam,
  InitFlowNode,
  GetMyMakeCard,
} from '@/api/user'
export default {
  components: { Card },
  // 组件名称
  name: '',
  // 组件参数 接收来自父组件的数据
  props: {},
  // 局部注册的组件
  // 组件状态值
  data() {
    return {
      loading: false, //加载
      finished: false, //数据完全加载结束为 true
      cardList: {},
      InitFlowNodeList: [],
      AltUserList: [],
      Show1: false,
      commNoticeList: [],
    }
  },
  // 计算属性
  computed: {},
  // 侦听器
  watch: {},
  created() {},
  mounted() {
    this.makeCardId()
  },
  // 组件方法
  methods: {
    // 制证申请
    makeCardId() {
      // 1. 生成制证id
      GenerateMakeCard({
        accToken: getToken(),
        CardType: 1,
      }).then((res) => {
        if (res.data.code == 0) {
          // this.Show = true;
          console.log(res.data.data, 'cardId')
          GetDraftMakeCard({ accToken: getToken(), cardType: 1 }).then((res) => {
            if (res.data.code == 0) {
              this.cardList = res.data.data
              InitFlowNode({
                instanceId: res.data.data.InstanceId,
                accToken: getToken(),
              }).then((res) => {
                if (res.data.code == 0) {
                  console.log(res.data.data, '进度流程')
                  this.Show = true
                  this.Show1 = true
                  this.activeTab = 'makeCard'
                  this.InitFlowNodeList = res.data.data
                  this.InitFlowNodeList.forEach((i) => {
                    if (i.SelfSelect == 1) {
                      // 自选 =>设置自选审批人
                      this.AltUserList = i.AltUserList
                    }
                  })
                }
              })
            }
          })
        } else {
          Toast(res.data.msg)
        }
      })
    },
    // 2.获取正在起草的制证申请
    getDraftMakeCard() {
      GetDraftMakeCard({ accToken: getToken(), cardType: 1 }).then((res) => {
        console.log(res, '正在起草的制证申请')
        if (res.data.code == 0) {
          // if (res.data) {
          //   this.Show = true;
          // } else {
          //   this.Show = false;
          // }
          this.cardList = res.data.data
          this.Show1 = true
          InitFlowNode({
            accToken: getToken(),
            instanceId: res.data.data.InstanceId,
          }).then((res) => {
            if (res.data.code == 0) {
              console.log(res.data.data, '进度流程')
              this.InitFlowNodeList = res.data.data
              this.InitFlowNodeList.forEach((i) => {
                if (i.SelfSelect == 1) {
                  // 自选 =>设置自选审批人
                  this.AltUserList = i.AltUserList
                }
              })
            }
          })
        } else {
          Toast(res.data.msg)
        }
      })
    },
  },
}
</script>

<style scoped>
.bottom {
  width: 100%;
  height: 54px;
  background-color: #fff;
  /* border: 1px solid #f2f2f2; */
  position: fixed;
  bottom: 0;
  text-align: center;
}

.bottom button {
  background: linear-gradient(to right, #56adda, #187fc8);
  color: #fff;
  width: 90%;
  height: 48px;
  line-height: 48px;
  border-radius: 8px;
}
</style>
