<template>
  <div class="content">
    <el-form ref="form" :model="user" :rules="rules" label-width="120px" style="width: 75%">
      <el-form-item label="姓名" prop="Recipient">
        <el-input :readonly="true" v-model="user.Name" placeholder="请输入申请人姓名" />
      </el-form-item>
      <el-form-item label="拼音检查" prop="Pinyin">
        <el-input v-model="user.Pinyin" placeholder="请检查拼音" />
        <el-popover placement="bottom" width="200" trigger="click">
          <div style="margin: 0px 0 6px">拼音纠错</div>
          <el-input v-model="RecipientPy" @input="getRightPy" />
          <el-button
            style="
              position: absolute;
              margin-left: 10px;
              top: 50%;
              transform: translateY(-50%);
            "
            type="primary"
            size="mini"
            @click="openPy"
            slot="reference"
            >拼音纠错</el-button
          >
        </el-popover>
      </el-form-item>
      <el-form-item label="护照号" prop="Passport">
        <el-input v-model="user.Passport" placeholder="请输入护照号" />
      </el-form-item>
      <el-form-item label="药物过敏史" prop="Allergy">
        <el-input v-model="user.Allergy" placeholder="请输入药物过敏史（没有可填无）" />
        <!-- <el-radio-group v-model="user.Allergy">
          <el-radio size="mini" border :label="'有'">有</el-radio>
          <el-radio size="mini" border :label="'无'">无</el-radio>
        </el-radio-group> -->
      </el-form-item>
      <el-form-item label="血型" prop="Blood">
        <el-select v-model="user.Blood" placeholder="请选择血型" @change="getBloodDesc">
          <el-option v-for="j in BloodList" :key="j.id" :label="j.name" :value="j.id" />
        </el-select>
      </el-form-item>
      <el-form-item label="紧急联系人姓名" prop="TouchMan">
        <el-input v-model="user.TouchMan" placeholder="请输入紧急联系人姓名" />
      </el-form-item>
      <el-form-item label="紧急联系电话" prop="TouchTel">
        <el-input v-model="user.TouchTel" placeholder="请输入紧急联系电话" />
      </el-form-item>

      <el-form-item label="收件人" prop="Recipient">
        <el-input v-model="user.Recipient" placeholder="请输入收件人" />
      </el-form-item>
      <el-form-item label="收件人电话" prop="RecMobile">
        <el-input v-model="user.RecMobile" placeholder="请输入收件人联系电话" />
      </el-form-item>
      <el-form-item label="收件地址" prop="RecAddr">
        <el-input v-model="user.RecAddr" placeholder="请输入收件地址" />
      </el-form-item>
      <el-form-item label="审核状态" prop="StatusDesc">
        <el-input :readonly="true" v-model="user.StatusDesc" placeholder="审核状态" />
      </el-form-item>
      <el-timeline style="margin: 36px 10px 0px 50px">
        <el-timeline-item v-for="(i, index) in InitFlowNodeList" :key="index">
          <!-- :timestamp="i.AddTime" -->
          {{ i.NodeName }} <span v-if="i.Makers">- {{ i.Makers }}</span>
          <!-- 单选 -->
          <el-dropdown style="float: right" trigger="click" @command="handleCommand" v-if="i.SelectMode == 1">
            <span class="el-dropdown-link">
              <el-button
                @click="handelApproval"
                v-if="i.NodeType == 'APPROVAL' && i.SelfSelect == 1"
                size="mini"
                icon="el-icon-plus"
              ></el-button>
            </span>
            <el-dropdown-menu slot="dropdown">
              <el-dropdown-item v-for="j in i.AltUserList" :key="j.id" :command="j.id">{{ j.name }}</el-dropdown-item>
            </el-dropdown-menu>
          </el-dropdown>

          <!-- 多选 -->
          <el-select
            class="shenpi"
            style="width: 92%"
            size="mini"
            v-if="i.SelectMode == 2 && i.NodeType == 'APPROVAL' && i.SelfSelect == 1"
            v-model="ids"
            multiple
            placeholder="请选择审批人"
            @change="handleCommand1"
          >
            <el-option v-for="j in i.AltUserList" :key="j.id" :label="j.name" :value="j.id" />
          </el-select>
        </el-timeline-item>
      </el-timeline>
      <el-form-item>
        <el-button v-if="user.Status == 1" type="warning" size="mini" :loading="editLoading" @click="preview"
          >预览</el-button
        >
        <el-button v-if="user.Status == 1" type="primary" size="mini" :loading="editLoading" @click="submit"
          >提交</el-button
        >
      </el-form-item>

      <!--存放二维码-->
      <el-dialog title="付款二维码" width="23%" :visible.sync="open" :close-on-click-modal="false">
        <div class="qrcode" id="qrcode" ref="ImgUrl"></div>
      </el-dialog>
    </el-form>
  </div>
</template>

<script>
import vPinyin from '@/utils/vue-py'
import { Dialog, Toast } from 'vant'
import QRCode from 'qrcodejs2'
import { SubmitMakeCard, PaymentByCode, StopMakeCard } from '@/api/user'
import { removeToken } from '@/utils/auth'
import { getToken, setToken } from '@/utils/auth'

export default {
  props: {
    cardList: {
      type: Object,
      default() {
        return {}
      },
    },
    InitFlowNodeList: {
      type: Array,
      default() {
        return []
      },
    },
    AltUserList: {
      type: Array,
      default() {
        return []
      },
    },
  },
  data() {
    const equalToPassword = (rule, value, callback) => {
      if (this.user.Allergy !== value) {
        callback(new Error('两次输入的密码不一致'))
      } else {
        callback()
      }
    }
    return {
      BloodList: [
        {
          id: 1,
          name: 'A型',
        },
        {
          id: 2,
          name: 'B型',
        },

        {
          id: 3,
          name: 'AB型',
        },
        {
          id: 4,
          name: 'O型',
        },

        {
          id: 5,
          name: 'RH阴性',
        },
        {
          id: 6,
          name: 'RH阳性',
        },
      ],
      open: false,
      editLoading: false,
      user: {
        // MakeId: "",
        // Passport: undefined,
        // Allergy: undefined,
        // TouchMan: undefined,
        // TouchTel: undefined,
        // Recipient: undefined,
        // RecMobile: undefined,
        // RecAddr: undefined,
      },
      // 表单校验
      rules: {
        Name: [{ required: true, message: '申请人姓名不能为空', trigger: 'blur' }],
        Pinyin: [{ required: true, message: '请检查拼音', trigger: 'blur' }],
        Passport: [{ required: true, message: '护照号不能为空（没有可填无）', trigger: 'blur' }],
        Allergy: [{ required: true, message: '药物过敏史不能为空（没有可填无）', trigger: 'blur' }],
        Blood: [{ required: true, message: '请选择血型', trigger: 'change' }],
        TouchMan: [{ required: true, message: '紧急联系人姓名不能为空', trigger: 'blur' }],
        TouchTel: [{ required: true, message: '紧急联系电话不能为空', trigger: 'blur' }],
        Recipient: [{ required: true, message: '收件人不能为空', trigger: 'blur' }],
        RecAddr: [{ required: true, message: '收件地址不能为空', trigger: 'blur' }],
        RecMobile: [
          {
            required: true,
            message: '收件人联系电话不能为空',
            trigger: 'blur',
          },
        ],
      },
      RecipientPy: '',
    }
  },
  created() {
    console.log(this.cardList, 'cardList')
    this.user = this.cardList
    // this.user.RecipientPy = vPinyin.chineseToPinYin(this.user.Name)
    if (this.user.Pinyin == null && this.user.Pinyin == '') {
      this.user.Pinyin = vPinyin.chineseToPinYin(this.user.Name)
    } else {
      this.user.Pinyin = this.user.Pinyin
    }
  },
  methods: {
    getBloodDesc(val) {
      this.BloodList.forEach((i) => {
        if (i.id == val) {
          this.user.BloodDesc = i.name
        }
      })
    },
    // 打开拼音纠错 弹框
    openPy() {
      this.RecipientPy = this.user.Pinyin
    },
    getRightPy() {
      this.user.Pinyin = this.RecipientPy
    },
    //跳转到公共页面
    gotoUrl(path) {
      if (window.__wxjs_environment === 'miniprogram') {
        window.wx.miniProgram.navigateTo({
          url: '../public/goto?path=' + path,
        })
      } else {
        this.$router.push(path)
      }
    },
    handleCommand(val) {
      console.log(val)
      this.AltUserList.forEach((i) => {
        if (i.id == val) {
          console.log(i.name)
          this.InitFlowNodeList.forEach((j) => {
            if (j.NodeType == 'APPROVAL' && j.SelfSelect == 1) {
              console.log(j)
              j.MakerIds = i.id
              j.Makers = i.name
            }
          })
        }
      })
    },
    handleCommand1() {
      var arr = []
      this.InitFlowNodeList.forEach((j) => {
        if (j.NodeType == 'APPROVAL' && j.SelfSelect == 1) {
          console.log()
          arr = j.AltUserList
        }
      })
      console.log(arr, 'arr')
      console.log(this.ids, 'ids')
      var Names = []
      var Ids = []
      this.ids.forEach((i) => {
        arr.forEach((j) => {
          if (j.id == i) {
            console.log(j.name)
            Names.push(j.name)
            Ids.push(j.id)
          }
        })
      })
      console.log(Names)
      console.log(Ids)
      this.InitFlowNodeList.forEach((j) => {
        if (j.NodeType == 'APPROVAL' && j.SelfSelect == 1) {
          j.MakerIds = Ids + ''
          j.Makers = Names + ''
        }
      })
      console.log(this.InitFlowNodeList, 'this.InitFlowNodeList')
    },
    // 付款
    handlePay() {
      setTimeout(() => {
        this.$refs.ImgUrl.innerHTML = ''
      }, 100)
      this.editLoading = true
      this.open = true
      PaymentByCode({ MakeId: this.user.MakeId }).then((res) => {
        this.editLoading = false
        if (res.code === 0) {
          var qrcode = new QRCode(this.$refs.ImgUrl, {
            text: res.codeurl, // 需要转换为二维码的内容
            width: 200,
            height: 200,
            colorDark: '#000000',
            colorLight: '#ffffff',
            correctLevel: QRCode.CorrectLevel.H,
          })
          console.log(qrcode)
        } else {
          Toast(res.data.msg)
        }
      })
    },
    // 预览
    preview() {
      this.$refs['form'].validate((valid) => {
        if (valid) {
          if (this.user.HeadImg) {
            this.user.HeadImg = this.user.HeadImg.replace(/\//g, '*')
          }
          if (this.user.QRCode) {
            this.user.QRCode = this.user.QRCode.replace(/\//g, '*')
          }
          var path = '/credential/' + JSON.stringify(this.user)

          if (window.__wxjs_environment === 'miniprogram') {
            window.wx.miniProgram.navigateTo({
              url: '../../../public/goto?path=' + path,
            })
          } else {
            this.$router.push(path)
          }
        }
      })
    },
    // 提交
    submit() {
      this.$refs['form'].validate((valid) => {
        if (valid) {
          for (var i = 0; i < this.InitFlowNodeList.length; i++) {
            if (this.InitFlowNodeList[i].NodeType == 'APPROVAL' && this.InitFlowNodeList[i].MakerIds == '') {
              Toast('请选择审批人')
              return false
            }
          }
          // this.editLoading = true;
          this.user.FlowNodes = JSON.stringify(this.InitFlowNodeList)
          this.user.accToken = getToken()
          // this.user.Pinyin = this.user.RecipientPy
          this.user.RecipientPy = this.user.Pinyin
          var userList = JSON.stringify(this.user)
          if (window.__wxjs_environment === 'miniprogram') {
            window.wx.miniProgram.navigateTo({
              url: '../../../public/payCard?user=' + userList + '&accToken=' + this.user.accToken,
            })
          } else {
            alert('请在小程序中打开')
          }
          // SubmitMakeCard(this.user).then((res) => {
          //   this.editLoading = false;
          //   if (res.data.code == 0) {
          //     Toast.success("提交成功");
          //     setTimeout(() => {
          //       // loading.close();
          //       this.close();
          //     }, 1000);
          //   } else {
          //     Toast(res.data.msg);
          //   }
          // });
        }
      })
    },
    async logout() {
      // await this.$store.dispatch('user/logout')
      removeToken()
      this.$router.push('/login?redirect=/Fdashboard')
      // this.$router.push(`/login?redirect=${this.$route.fullPath}`)
    },
    close() {
      // this.$tab.closePage();
      // this.$router.push("/");
      this.$router.go(-1)
    },
  },
}
</script>

<style scoped>
.content {
  box-sizing: border-box;
  padding: 20px 0 20px;
}
.qrcode /deep/ img {
  margin: auto !important;
}

.el-timeline-item {
  padding-bottom: 50px;
}

/deep/ .el-timeline-item__tail {
  border-left: 2px solid #397affbf;
}
/deep/ .el-timeline-item__node {
  background-color: #397aff;
}

/deep/ .shenpi .el-input__inner {
  border: none !important;
}
</style>
